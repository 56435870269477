import './breadcrumb.css'

import {
  Box,
  Breadcrumb as _Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Picto
} from '@stars-ecom/shared-atoms-ui'
import dropRight from 'lodash/dropRight'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import React, { memo, useContext, useEffect, useState } from 'react'

import { WebsiteContext } from '../context'
import JsonLd from '../utils/jsonLd'

const Breadcrumb = (props) => {
  const { data: _data, truncated: _truncated = false } = props
  console.log('data', _data);
  const websiteContext = useContext(WebsiteContext)
  const [data, setData] = useState(_data)
  const [truncated, setTruncated] = useState(_truncated)
  const [jsonLdData, setJsonLdData] = useState({})

  if (isEmpty(_data) || size(_data) == 1) {
    return null
  }

  useEffect(() => {
    if (websiteContext?.isMobile && _truncated) {
      setTruncated(true)
      setData(dropRight(_data))
    } else {
      setTruncated(false)
      setData(_data)
    }
  }, [_truncated, websiteContext.isMobile, _data])

  useEffect(() => {
    setData(truncated ? dropRight(_data) : _data)
    buildJsonLd()
  }, [truncated, _data])

  const urlOrigin = typeof window !== 'undefined' ? window.location.origin : ''

  const buildJsonLd = () => {
    if (typeof window !== 'undefined') {
      const itemListElement = _data.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        name: item.label,
        item: urlOrigin + item.link
      }))

      const wrapper = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: itemListElement
      }
      setJsonLdData(wrapper)
    }
  }

  return (
    <Flex height="100%">
      {typeof jsonLdData['@type'] != 'undefined' && <JsonLd jsonLdData={jsonLdData} />}
      <Box display={truncated ? 'block' : 'none'}>
        <Picto
          icon="chevronLeft"
          color="#000"
          width="11px"
          height="11px"
          className="chevronBreadcrumb"
          style={{ marginTop: '9px', marginRight: '4px' }}
        />
      </Box>

      <_Breadcrumb
        spacing="4px"
        separator="/"
        fontFamily="PT Sans"
        className={truncated ? 'breadcrumb-truncated' : 'breadcrumb'}>
        {data.map((d, i) => (
          <BreadcrumbItem key={`breadcrumb_${i}`} isCurrentPage={i === size(data) - 1}>
            <BreadcrumbLink
              href={i === size(data) - 1 ? undefined : d?.link}
              isCurrentPage={i === size(data) - 1}>
              {d?.label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </_Breadcrumb>
    </Flex>
  )
}

export default memo(Breadcrumb)
