import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import trim from 'lodash/trim'
import * as Yup from 'yup'

import {
  regExpCity,
  regexPortableNumber,
  regExpPhoneNumber,
  regExpZipCode
} from '../../constants/account'

const formSettings = [
  {
    title: 'Mon identité',
    fields: [
      {
        label: 'Civilité',
        type: 'Radio',
        values: ['Madame', 'Monsieur'],
        attrKey: 'currentUser.title',
        isRequired: true,
        hideLabel: false,
        validator: () => () => Yup.string().nullable().required('Ce champ est obligatoire')
      },
      {
        label: 'Nom',
        type: 'Input',
        attrKey: 'currentUser.lastName',
        readOnly: true,
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .max(20, 'Doit être de 20 caractères maximum')
            .required('Ce champ est obligatoire')
      },
      {
        label: 'Prénom',
        type: 'Input',
        attrKey: 'currentUser.firstName',
        readOnly: true,
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .max(20, 'Doit être de 20 caractères maximum')
            .required('Ce champ est obligatoire')
      },
      {
        label: 'E-mail',
        type: 'Input',
        attrKey: 'currentUser.emailAddress',
        helper: 'Ex: nom@yahoo.fr',
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .email('Adresse e-mail invalide')
            .required('Ce champ est obligatoire')
      },
      {
        label: 'Confirmez votre e-mail',
        type: 'Input',
        attrKey: 'currentUser.confirmEmailAddress',
        isRequired: true,
        validator: (origin) => (current) =>
          Yup.string()
            .nullable()
            .test('confirmed', 'Les adresses e-mail ne correspondent pas', (value) => {
              return (
                (origin?.currentUser?.emailAddress !== current?.currentUser?.emailAddress &&
                  current?.currentUser?.emailAddress === value) ||
                origin?.currentUser?.emailAddress === current?.currentUser?.emailAddress
              )
            }),
        isVisible: (origin) => (current) =>
          origin?.currentUser?.emailAddress !== current?.currentUser?.emailAddress
      },
      {
        label: 'Date de naissance',
        type: 'Date',
        attrKey: 'currentUser.customFields.birthdate',
        isRequired: true,
        validator: () => () => Yup.date().nullable().required('Ce champ est obligatoire')
      }
    ]
  },
  {
    title: 'Mon adresse de facturation',
    fields: [
      {
        label: 'Adresse',
        type: 'Input',
        placeholder: 'N° et voie (rue, allée, avenue, boulevard...)',
        attrKey: 'billingAddress.streetLine1',
        helper: '1 rue Victor Hugo',
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .max(40, 'Doit être de 40 caractères maximum')
            .required('Ce champ est obligatoire')
      },
      {
        type: 'Input',
        placeholder: 'Appartement, étage, porte, couloir...',
        helper: 'Appt 25, Etage 3, Esc B',
        attrKey: 'billingAddress.streetLine2',
        validator: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
      },
      {
        type: 'Input',
        placeholder: 'Bâtiment, résidence, entrée...',
        helper: 'Bât B, Rés Foch, Entrée B',
        attrKey: 'billingAddress.customFields.addressLine3',
        validator: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
      },
      {
        type: 'Input',
        placeholder: 'Lieu-dit',
        helper: 'Bois Joly',
        attrKey: 'billingAddress.customFields.addressLine4',
        validator: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
      },
      {
        label: 'Ville',
        type: 'Input',
        helper: 'Ex: PARIS',
        attrKey: 'billingAddress.city',
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .max(30, 'Doit être de 30 caractères maximum')
            .required('Ce champ est obligatoire')
            .test(
              'isSet',
              'Veuillez indiquer une ville valide.',
              (v) => regExpCity.test(v) || isNil(v) || trim(v) === ''
            )
      },
      {
        label: 'Code postal',
        helper: 'Veuillez indiquer un code postal valide.',
        type: 'Input',
        attrKey: 'billingAddress.postalCode',
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .required('Ce champ est obligatoire')
            .matches(regExpZipCode[process.env.GATSBY_API_COUNTRY_CODE], {
              message: 'Veuillez indiquer un code postal valide.',
              excludeEmptyString: true
            })
      },
      {
        label: 'Pays',
        type: 'Input',
        attrKey: 'billingAddress.country.name',
        readOnly: true,
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .max(20, 'Doit être de 20 caractères maximum')
            .required('Ce champ est obligatoire')
      }
    ]
  },
  {
    title: 'Téléphone',
    topContent:
      "Merci d’indiquer au moins un numéro valide (indispensable pour l'expédition de votre commande)",
    fields: [
      {
        label: 'Fixe',
        type: 'Input',
        helper: 'Ex: 01XXXXXXXX',
        attrKey: 'billingAddress.phoneNumber',
        validator: () => (current) =>
          Yup.string()
            .nullable()
            .test(
              'isSet',
              'Veuillez introduire un numéro de téléphone valide',
              (v) =>
                regExpPhoneNumber[process.env.GATSBY_API_COUNTRY_CODE].test(v) ||
                isNil(v) ||
                trim(v) === ''
            )
            .test(
              'at-least-one-property',
              'Veuillez renseigner au moins un numéro de téléphone.',
              (v) => !!(v || current?.billingAddress?.customFields?.cellPhoneNumber.trim())
            )
      },
      {
        label: 'Portable',
        helper: 'Ex: 06XXXXXXXX ou 07XXXXXXXX',
        type: 'Input',
        attrKey: 'billingAddress.customFields.cellPhoneNumber',
        validator: () => (current) =>
          Yup.string()
            .nullable()
            .test(
              'isSet',
              'Veuillez introduire un numéro de téléphone valide',
              (v) =>
                regexPortableNumber[process.env.GATSBY_API_COUNTRY_CODE].test(v) ||
                isNil(v) ||
                trim(v) === ''
            )
            .test(
              'at-least-one-property',
              'Veuillez renseigner au moins un numéro de téléphone.',
              (v) => !!(v || current?.billingAddress?.phoneNumber.trim())
            )
      }
    ]
  },
  {
    title: 'Mon mot de passe',
    fields: [
      {
        label: 'Mot de passe actuel',
        type: 'Password',
        attrKey: 'currentPassword',
        isRequired: true,
        validator: (origin) => (current) =>
          Yup.string()
            .nullable()
            .test(
              'hasChanged',
              'Veuillez renseigner votre mot de passe pour faire un changement',
              (v) => {
                return (
                  (isEqual(origin?.currentUser, current?.currentUser) &&
                    isEqual(origin?.billingAddress, current?.billingAddress) &&
                    !current?.newPassword) ||
                  (v && trim(v) !== '')
                )
              }
            )
      },
      {
        label: 'Changer mon mot de passe',
        type: 'Password',
        attrKey: 'newPassword',
        validator: {
          label: 'Votre mot de passe doit respecter les 5 critères suivants :',
          minRulesValidated: 5,
          rules: [
            {
              label: 'Minimum 8 caractères',
              validator: () => () => Yup.string().nullable().min(8),
              weight: 0.5
            },
            {
              label: 'Une minuscule',
              validator: () => () => Yup.string().matches(/[a-z]/gm),
              weight: 0.2
            },
            {
              label: 'Une majuscule',
              validator: () => () => Yup.string().matches(/[A-Z]/gm),
              weight: 0.2
            },
            {
              label: 'Un chiffre',
              validator: () => () => Yup.string().matches(/[0-9]/gm),
              weight: 0.1
            },
            {
              label: 'Un caractère spécial',
              validator: () => () => Yup.string().matches(/[!@#$%^&*()_+\-=[\]{};:\\|,.<>/?~]/gm),
              weight: 0.1
            }
          ]
        }
        /*validator: () => () =>
          Yup.string()
            .nullable()
            .test(
              'validPassword',
              `<div style="font-size:11px" class="password-error-message"><strong>Votre mot de passe doit contenir au moins 4 des 5 types suivants :</strong>
              <ul style="font-weight:400">
                <li>Minimum 8 caractères (obligatoire)</li>
                <li>Une minuscule</li>
                <li>Une majuscule</li>
                <li>Un chiffre</li>
                <li>Un caractère spécial</li></ul></div>`,
              (v) => {
                return !v || trim(v) === '' || passwordValidates(v)
              }
            ),
        passwordErrorComponent: true*/
      },
      {
        label: 'Confirmer mon nouveau mot de passe',
        type: 'Password',
        attrKey: 'confirmNewPassword',
        isRequired: true,
        isVisible: () => (current) => current?.newPassword,
        validator: () => (current) =>
          Yup.string()
            .nullable()
            .test('confirmed', 'Les mots de passe ne sont pas identiques', (v) => {
              return current?.newPassword === v || !current?.newPassword
            })
      }
    ]
  }
]

export default formSettings
