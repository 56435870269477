const regExpZipCode = {
  FR: /^(?:[0-8]\d|9[0-8])\d{3}$/,
  BE: /^(?:(?:[1-9])(?:\d{3}))$/,
  CH: /^[1-9]\d{3}$/
}

const regExpasswd =
  /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])).{8,20}|((?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*'¨µ¤|"/=<>~£€²`§])).{8,20}|((?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*'¨µ¤|"/=<>~£€²`§])).{8,20}|((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*'¨µ¤|"/=<>~£€²`§])).{8,20}$/g

const regExpPhoneNumber = {
  FR: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
  BE: /^((\+|00)32\s?|0)(\d\s?\d{3}|\d{2}\s?\d{2})(\s?\d{2}){2}$/,
  CH: /^(?:(?:|0{1,2}|\+{0,2})41(?:|\(0\))|0)([1-9]\d)(\d{3})(\d{2})(\d{2})$/
}

const regexPortableNumber = {
  FR: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
  BE: /^((\+|00)32\s?|0)4(60|[789]\d)(\s?\d{2}){3}$/,
  CH: /^(?:(?:|0{1,2}|\+{0,2})41(?:|\(0\))|0)([1-9]\d)(\d{3})(\d{2})(\d{2})$/
}

const regExpCity = /^[a-zA-Z][a-zA-Z- ]*[a-zA-Z]$/

const civilitie = [
  { name: 'Monsieur', label: 'Monsieur' },
  { name: 'Madame', label: 'Madame' }
]

const identity = [
  {
    name: 'civilite',
    title: 'CIVILITÉ *',
    classN: 'civilite',
    typeInput: 'radio',
    values: civilitie
  },
  {
    name: 'lastName',
    title: 'NOM *',
    typeInput: 'text',
    background: '#eee',
    disabled: true
  },
  {
    name: 'firstName',
    title: 'PRÉNOM *',
    typeInput: 'text',
    background: '#eee',
    disabled: true
  },
  {
    name: 'emailAddress',
    title: 'ADRESSE E-MAIL*',
    typeInput: 'email'
  },
  {
    name: 'emailConfirmation',
    title: 'CONFIRMATION *',
    typeInput: 'email'
  },

  {
    name: 'birthdate',
    title: 'DATE DE NAISSANCE *',
    typeInput: 'datePicker',
    dateFormat: 'dd-MM-yyyy'
  }
]
const userAdrs = [
  {
    name: 'addressLine1',
    title: 'ADRESSE *',
    typeInput: 'text',
    placeholderInput: 'N° et voie (rue, allée, avenue, boulevard...)'
  },
  {
    name: 'addressLine2',
    typeInput: 'text',
    placeholderInput: 'Ex : Appt 25, Etage 3, Esc B'
  },
  {
    name: 'addressLine3',
    typeInput: 'text',
    placeholderInput: 'Bâtiment, résidence, entrée...'
  },
  {
    name: 'addressLine4',
    typeInput: 'text',
    placeholderInput: 'Lieu-dit'
  },
  {
    name: 'postalCode',
    title: 'CODE POSTAL *',
    typeInput: 'text'
  },
  {
    name: 'city',
    title: 'VILLE *',
    typeInput: 'text'
  },
  {
    name: 'country',
    title: 'PAYS *',
    typeInput: 'text',
    disabled: true,
    background: '#eee'
  }
]

const identityAdr = [
  {
    name: 'fullName',
    title: "NOM DE L'ADRESSE *",
    placeholderInput: "Nom de l'adresse *",
    typeInput: 'text'
  },
  {
    name: 'civility',
    title: 'CIVILITÉ *',
    classN: 'civilite',
    typeInput: 'radio',
    values: civilitie,
    disabled: true
  },
  {
    name: 'lastName',
    title: 'NOM *',
    typeInput: 'text',
    background: '#eee',
    disabled: true
  },
  {
    name: 'firstName',
    title: 'PRÉNOM *',
    typeInput: 'text',
    background: '#eee',
    disabled: true
  },
  {
    name: 'addressLine1',
    title: 'ADRESSE *',
    typeInput: 'text',
    placeholderInput: 'N° et voie (rue, allée, avenue, boulevard...) *',
    disabled: true,
    background: '#eee'
  },
  {
    name: 'addressLine2',
    typeInput: 'text',
    placeholderInput: 'Ex : Appt 25, Etage 3, Esc B',
    disabled: true,
    background: '#eee'
  },
  {
    name: 'addressLine3',
    typeInput: 'text',
    placeholderInput: 'Bâtiment, résidence, entrée...',
    disabled: true,
    background: '#eee'
  },
  {
    name: 'addressLine4',
    typeInput: 'text',
    placeholderInput: 'Lieu-dit',
    disabled: true,
    background: '#eee'
  },
  {
    name: 'postalCode',
    title: 'CODE POSTAL *',
    typeInput: 'text',
    placeholderInput: 'Code Postal *',
    disabled: true,
    background: '#eee'
  },
  {
    name: 'city',
    title: 'VILLE *',
    typeInput: 'text',
    placeholderInput: 'Ville *',
    disabled: true,
    background: '#eee'
  },
  {
    name: 'country',
    title: 'PAYS *',
    placeholderInput: 'Pays *',
    typeInput: 'text',
    disabled: true,
    background: '#eee'
  }
]

const userPhone = [
  {
    name: 'phoneNumber',
    title: 'FIXE',
    typeInput: 'text'
  },
  {
    name: 'cellPhoneNumber',
    title: 'PORTABLE',
    typeInput: 'text'
  }
]
const userPhoneAdr = [
  {
    name: 'phoneNumber',
    title: 'FIXE',
    typeInput: 'text',
    placeholderInput: 'Téléphone fixe *',
    disabled: true,
    background: '#eee'
  },
  {
    name: 'cellPhoneNumber',
    title: 'PORTABLE',
    typeInput: 'text',
    placeholderInput: 'Téléphone mobile *',
    disabled: true,
    background: '#eee'
  }
]
const userPass = [
  {
    name: 'currentPassword',
    title: 'MOT DE PASSE ACTUEL *',
    typeInput: 'password'
  },
  {
    name: 'password',
    title: 'CHANGER MON MOT DE PASSE',
    typeInput: 'password'
  },
  {
    name: 'confirmation',
    title: 'CONFIRMER MON NOUVEAU MOT DE PASSE',
    typeInput: 'password'
  }
]
const userPassCreate = [
  {
    name: 'password',
    title: 'MOT DE PASSE *',
    typeInput: 'password'
  },
  {
    name: 'confirmation',
    title: 'CONFIRMATION *',
    typeInput: 'password'
  },
  {
    name: 'newsletter',
    text:
      'Je souhaite recevoir des offres (ciblées) de la société ' +
      process.env.GATSBY_API_WEBSITE +
      ' par les canaux de communication renseignés sur le présent formulaire.',
    typeInput: 'checkbox',
    classN: 'checkboxlabel'
  },
  {
    name: 'targeting',
    text:
      'Je souhaite recevoir des offres (ciblées) des partenaires sélectionnés par la société ' +
      process.env.GATSBY_API_WEBSITE +
      ' par les canaux de communication renseignés sur le présent formulaire.',
    typeInput: 'checkbox',
    classN: 'checkboxlabel'
  }
]
const sectionEdit = [
  {
    title: 'Mon Identité',
    content: identity
  },
  {
    title: 'Mon adresse de facturation',
    content: userAdrs
  },
  {
    title: 'Téléphone *',
    subTitle:
      "Merci d’indiquer au moins un numéro valide (indispensable pour l'expédition de votre commande)",
    content: userPhone
  },
  {
    title: 'Mon mot de passe',
    content: userPass
  }
]

const sectionCreate = [
  {
    title: 'Votre identité',
    content: identity
  },
  {
    title: 'Votre mot de passe',
    content: userPassCreate
  }
]

const sectionAdr = [
  {
    content: identityAdr
  },
  {
    title: 'Téléphone *',
    subTitle:
      "Merci d’indiquer au moins un numéro valide (indispensable pour l'expédition de votre commande)",
    content: userPhoneAdr
  }
]
export {
  regExpCity,
  regExpZipCode,
  regExpasswd,
  regExpPhoneNumber,
  regexPortableNumber,
  sectionEdit,
  sectionAdr,
  sectionCreate
}
