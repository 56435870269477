import isDate from 'lodash/isDate'
import isNil from 'lodash/isNil'
import trim from 'lodash/trim'
import * as Yup from 'yup'

import {
  regExpCity,
  regexPortableNumber,
  regExpPhoneNumber,
  regExpZipCode
} from '../../constants/account'

const formSettings = [
  {
    title: 'Vos identifiants',
    fields: [
      {
        label: 'E-mail',
        type: 'Input',
        attrKey: 'currentUser.emailAddress',
        helper: 'Ex: nom@yahoo.fr',
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .email('Adresse e-mail invalide')
            .required('Ce champ est obligatoire')
      },
      {
        label: 'Confirmez votre e-mail',
        type: 'Input',
        attrKey: 'confirmEmailAddress',
        isRequired: true,
        validator: () => (current) =>
          Yup.string()
            .nullable()
            .test(
              'confirmed',
              'Les adresses e-mail ne correspondent pas',
              (value) =>
                !current?.currentUser?.emailAddress || current?.currentUser?.emailAddress === value
            )
      },
      {
        label: 'Mot de passe',
        type: 'Password',
        attrKey: 'password',
        isRequired: true,
        validator: {
          label: 'Votre mot de passe doit respecter les 5 critères suivants :',
          minRulesValidated: 5,
          rules: [
            {
              label: 'Minimum 8 caractères',
              validator: () => () => Yup.string().nullable().min(8),
              weight: 0.5
            },
            {
              label: 'Une minuscule',
              validator: () => () => Yup.string().matches(/[a-z]/gm),
              weight: 0.2
            },
            {
              label: 'Une majuscule',
              validator: () => () => Yup.string().matches(/[A-Z]/gm),
              weight: 0.2
            },
            {
              label: 'Un chiffre',
              validator: () => () => Yup.string().matches(/[0-9]/gm),
              weight: 0.1
            },
            {
              label: 'Un caractère spécial',
              validator: () => () => Yup.string().matches(/[!@#$%^&*()_+\-=[\]{};:\\|,.<>/?~]/gm),
              weight: 0.1
            }
          ]
        }
      },
      {
        label: 'Confirmer votre mot de passe',
        type: 'Password',
        attrKey: 'currentUser.confirmPassword',
        isRequired: true,
        validator: () => (current) =>
          Yup.string()
            .nullable()
            .test('confirmed', 'Les mots de passe ne sont pas identiques', (v) => {
              return current?.password === v || !current?.password
            })
      }
    ]
  },
  {
    title: 'Vos coordonnées',
    fields: [
      {
        label: '',
        type: 'Radio',
        values: ['Madame', 'Monsieur'],
        attrKey: 'currentUser.title',
        isRequired: true,
        hideLabel: true,
        validator: () => () => Yup.string().nullable().required('Ce champ est obligatoire')
      },
      {
        label: 'Nom',
        type: 'Input',
        attrKey: 'currentUser.lastName',
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .max(20, 'Doit être de 20 caractères maximum')
            .required('Ce champ est obligatoire')
      },
      {
        label: 'Prénom',
        type: 'Input',
        attrKey: 'currentUser.firstName',
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .max(20, 'Doit être de 20 caractères maximum')
            .required('Ce champ est obligatoire')
      },
      {
        label: 'Date de naissance',
        type: 'Date',
        attrKey: 'currentUser.customFields.birthdate',
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .test('idDate', 'Ce champ est obligatoire', (value) => {
              return value && isDate(new Date(value))
            })
      },
      {
        label: 'Code postal',
        helper: 'Veuillez indiquer un code postal valide.',
        type: 'Input',
        attrKey: 'billingAddress.postalCode',
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .required('Ce champ est obligatoire')
            .matches(regExpZipCode[process.env.GATSBY_API_COUNTRY_CODE], {
              message: 'Veuillez indiquer un code postal valide.',
              excludeEmptyString: true
            })
      },
      {
        label: 'Adresse',
        type: 'Input',
        placeholder: 'N° et voie (rue, allée, avenue, boulevard...)',
        attrKey: 'billingAddress.streetLine1',
        helper: '1 rue Victor Hugo',
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .max(40, 'Doit être de 40 caractères maximum')
            .required('Ce champ est obligatoire')
      },
      {
        type: 'Input',
        placeholder: 'Appartement, étage, porte, couloir...',
        helper: 'Appt 25, Etage 3, Esc B',
        attrKey: 'billingAddress.streetLine2',
        validator: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
      },
      {
        type: 'Input',
        placeholder: 'Bâtiment, résidence, entrée...',
        helper: 'Bât B, Rés Foch, Entrée B',
        attrKey: 'billingAddress.customFields.addressLine3',
        validator: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
      },
      {
        type: 'Input',
        placeholder: 'Lieu-dit',
        helper: 'Bois Joly',
        attrKey: 'billingAddress.customFields.addressLine4',
        validator: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
      },
      {
        label: 'Ville',
        type: 'Input',
        helper: 'Ex: PARIS',
        attrKey: 'billingAddress.city',
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .max(30, 'Doit être de 30 caractères maximum')
            .required('Ce champ est obligatoire')
            .test(
              'isSet',
              'Veuillez indiquer une ville valide.',
              (v) => regExpCity.test(v) || isNil(v) || trim(v) === ''
            )
      },
      {
        label: 'Pays',
        type: 'Input',
        attrKey: 'billingAddress.country.name',
        readOnly: true,
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .max(20, 'Doit être de 20 caractères maximum')
            .required('Ce champ est obligatoire')
      },
      {
        label: 'Contact de livraison :',
        type: 'title'
      },
      {
        text: "Afin d'assurer le meilleur suivi possible, merci de renseigner au moins un numéro de téléphone valide, indispensable pour l'expédition de votre commande.",
        type: 'text'
      },
      {
        label: 'Fixe',
        type: 'Input',
        helper: 'Ex: 01XXXXXXXX',
        attrKey: 'billingAddress.phoneNumber',
        validator: () => (current) =>
          Yup.string()
            .nullable()
            .test(
              'isSet',
              'Veuillez introduire un numéro de téléphone valide',
              (v) =>
                regExpPhoneNumber[process.env.GATSBY_API_COUNTRY_CODE].test(v) ||
                isNil(v) ||
                trim(v) === ''
            )
            .test(
              'oneOfRequired',
              'Veuillez renseigner au moins un numéro de téléphone.',
              (v) => v || current?.billingAddress?.customFields?.cellPhoneNumber
            )
      },
      {
        label: 'Portable',
        helper: 'Ex: 06XXXXXXXX ou 07XXXXXXXX',
        type: 'Input',
        attrKey: 'billingAddress.customFields.cellPhoneNumber',
        validator: () => (current) =>
          Yup.string()
            .nullable()
            .test(
              'isSet',
              'Veuillez introduire un numéro de téléphone valide',
              (v) =>
                regexPortableNumber[process.env.GATSBY_API_COUNTRY_CODE].test(v) ||
                isNil(v) ||
                trim(v) === ''
            )
            .test(
              'oneOfRequired',
              'Veuillez renseigner au moins un numéro de téléphone.',
              (v) => v || current?.billingAddress?.phoneNumber
            )
      },
      {
        label: 'Restons en contact :',
        type: 'title'
      },
      {
        label:
          'Je souhaite recevoir des offres (ciblées) de la société ' +
          process.env.GATSBY_API_WEBSITE +
          ' par les canaux de communication renseignés sur le présent formulaire.',
        type: 'yesNoRadio',
        values: ['Oui', 'Non'],
        attrKey: 'optinEmail',
        isRequired: false,
        hideLabel: false
        //validator: () => () => Yup.string().nullable().required('Ce champ est obligatoire')
      },
      {
        label:
          'Je souhaite recevoir des offres (ciblées) des partenaires sélectionnés par la société ' +
          process.env.GATSBY_API_WEBSITE +
          ' par les canaux de communication renseignés sur le présent formulaire.',
        type: 'yesNoRadio',
        attrKey: 'optinPartnerEmail',
        isRequired: false,
        hideLabel: false
        //validator: () => () => Yup.string().nullable().required('Ce champ est obligatoire')
      }
    ]
  }
]

export default formSettings
