import isNil from 'lodash/isNil'
import trim from 'lodash/trim'
import * as Yup from 'yup'
// TODO handle country in schema
const regExpZipCode = {
  FR: /^(?:[0-8]\d|9[0-8])\d{3}$/,
  BE: /^(?:(?:[1-9])(?:\d{3}))$/,
  CH: /^[1-9]\d{3}$/
}

const regExpCity = /^[a-zA-Z][a-zA-Z- ]*[a-zA-Z]$/

const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'application/pdf',
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/tiff',
  'image/bmp',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation'
]
const FILE_SIZE = 3000000
const schema = {
  emailAddress: {
    label: 'Adresse e-mail',
    type: 'Input',
    attrKey: 'emailAddress',
    required: true,
    schema: () => () =>
      Yup.string().nullable().email('Adresse email invalide').required('Ce champ est obligatoire')
  },
  client: {
    label: 'Votre n° de client',
    type: 'Input',
    attrKey: 'client',
    schema: () => () => Yup.string().nullable().max(30, 'Doit être de 30 caractères maximum')
  },
  commande: {
    label: 'Votre n° de commande',
    type: 'Input',
    attrKey: 'commande',
    schema: () => () => Yup.string().nullable().max(30, 'Doit être de 30 caractères maximum')
  },
  civility: {
    label: 'Civilité',
    type: 'Radio',
    values: ['Madame', 'Monsieur'],
    attrKey: 'title',
    required: true,
    schema: () => () => Yup.string().nullable().required('Ce champ est obligatoire')
  },
  lastName: {
    label: 'Nom',
    type: 'Input',
    attrKey: 'lastName',
    required: true,
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(20, 'Doit être de 20 caractères maximum')
        .required('Ce champ est obligatoire')
  },
  firstName: {
    label: 'Prénom',
    type: 'Input',
    attrKey: 'firstName',
    required: true,
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(20, 'Doit être de 20 caractères maximum')
        .required('Ce champ est obligatoire')
  },
  city: {
    label: 'Ville',
    type: 'Input',
    attrKey: 'city',
    required: true,
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(30, 'Doit être de 30 caractères maximum')
        .required('Ce champ est obligatoire')
        .test(
          'isSet',
          'Veuillez indiquer une ville valide.',
          (v) => regExpCity.test(v) || isNil(v) || trim(v) === ''
        )
  },
  country: {
    label: 'Pays',
    type: 'Input',
    attrKey: 'country',
    readOnly: true,
    required: true,
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(20, 'Doit être de 20 caractères maximum')
        .required('Ce champ est obligatoire')
  },
  postalCode: {
    label: 'Code postal',
    type: 'Input',
    attrKey: 'postalCode',
    required: true,
    schema: () => () =>
      Yup.string()
        .nullable()
        .required('Ce champ est obligatoire')
        .matches(regExpZipCode[process.env.GATSBY_API_COUNTRY_CODE], {
          message: 'Veuillez indiquer un code postal valide.',
          excludeEmptyString: true
        })
  },
  addressLine1: {
    label: 'IMMEUBLE - BÂTIMENT - RÉSIDENCE',
    type: 'Input',
    attrKey: 'addressLine1',
    schema: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
  },
  addressLine2: {
    type: 'Input',
    label: 'VOIE',
    attrKey: 'addressLine2',
    schema: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
  },
  addressLine3: {
    label: 'LIEU-DIT',
    type: 'Input',
    attrKey: 'addressLine3',
    schema: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
  },
  message: {
    label: 'MESSAGE *',
    type: 'Textarea',
    attrKey: 'message',
    schema: () => () => Yup.string().nullable().required('Ce champ est obligatoire')
  },
  attachment: {
    label: 'PIÈCE JOINTE (MAXIMUM 3 MO)',
    type: 'File',
    attrKey: 'attachment',
    schema: () => () =>
      Yup.mixed()
        .nullable()
        .notRequired()
        .test(
          'FILE_SIZE',
          'Le fichier téléchargé est trop volumineux.',
          (value) => isNil(value) || value.size <= FILE_SIZE
        )
        .test(
          'FILE_FORMAT',
          "Le format du fichier téléchargé n'est pas pris en charge.",
          (value) => isNil(value) || SUPPORTED_FORMATS.includes(value.type)
        )
  }
}

export default schema
