import * as Yup from 'yup'

const formSettings = [
  {
    title: "Saisir le nom de l'adresse",
    fields: [
      {
        label: "Nom de l'adresse",
        type: 'Input',
        attrKey: 'fullName',
        helper: 'Ex: Bureau, Domicile...',
        readOnly: false,
        isRequired: true,
        validator: () => () =>
          Yup.string()
            .nullable()
            .max(20, 'Doit être de 20 caractères maximum')
            .required('Ce champ est obligatoire')
      },
      {
        label: 'Civilité',
        type: 'Radio',
        values: ['Madame', 'Monsieur'],
        attrKey: 'customFields.civility',
        readOnly: true,
        isRequired: true,
        hideLabel: false
        //validator: () => () => Yup.string().nullable().required('Ce champ est obligatoire')
      },
      {
        label: 'Nom',
        type: 'Input',
        attrKey: 'customFields.lastName',
        readOnly: true,
        isRequired: true
        /*validator: () => () =>
          Yup.string()
            .nullable()
            .max(20, 'Doit être de 20 caractères maximum')
            .required('Ce champ est obligatoire')*/
      },
      {
        label: 'Prénom',
        type: 'Input',
        attrKey: 'customFields.firstName',
        readOnly: true,
        isRequired: true
        /*validator: () => () =>
          Yup.string()
            .nullable()
            .max(20, 'Doit être de 20 caractères maximum')
            .required('Ce champ est obligatoire')*/
      },
      {
        label: 'Adresse',
        type: 'Input',
        placeholder: 'N° et voie (rue, allée, avenue, boulevard...)',
        attrKey: 'streetLine1',
        helper: '1 rue Victor Hugo',
        readOnly: true,
        isRequired: true
        /*validator: () => () =>
          Yup.string()
            .nullable()
            .max(40, 'Doit être de 40 caractères maximum')
            .required('Ce champ est obligatoire')*/
      },
      {
        type: 'Input',
        placeholder: 'Appartement, étage, porte, couloir...',
        helper: 'Appt 25, Etage 3, Esc B',
        readOnly: true,
        attrKey: 'streetLine2'
        //validator: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
      },
      {
        type: 'Input',
        placeholder: 'Bâtiment, résidence, entrée...',
        helper: 'Bât B, Rés Foch, Entrée B',
        readOnly: true,
        attrKey: 'customFields.addressLine3'
        // validator: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
      },
      {
        type: 'Input',
        placeholder: 'Lieu-dit',
        helper: 'Bois Joly',
        attrKey: 'customFields.addressLine4',
        readOnly: true
        //validator: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
      },
      {
        label: 'Code postal',
        helper: 'Veuillez indiquer un code postal valide.',
        type: 'Input',
        attrKey: 'postalCode',
        readOnly: true,
        isRequired: true
        /*validator: () => () =>
          Yup.string().nullable().required('Ce champ est obligatoire').matches(regExpZipCode[process.env.GATSBY_API_COUNTRY_CODE], {
            message: 'Veuillez indiquer un code postal valide.',
            excludeEmptyString: true
          })*/
      },
      {
        label: 'Ville',
        type: 'Input',
        helper: 'Ex: PARIS',
        attrKey: 'city',
        readOnly: true,
        isRequired: true
        /*validator: () => () =>
          Yup.string()
            .nullable()
            .max(30, 'Doit être de 30 caractères maximum')
            .required('Ce champ est obligatoire')*/
      },
      {
        label: 'Pays',
        type: 'Input',
        attrKey: 'country.name',
        readOnly: true,
        isRequired: true
        /*validator: () => () =>
          Yup.string()
            .nullable()
            .max(20, 'Doit être de 20 caractères maximum')
            .required('Ce champ est obligatoire')*/
      }
    ]
  },
  {
    title: 'Téléphone',
    topContent:
      "Merci d’indiquer au moins un numéro valide (indispensable pour l'expédition de votre commande)",
    fields: [
      {
        label: 'Fixe',
        type: 'Input',
        helper: 'Ex: 01XXXXXXXX',
        attrKey: 'phoneNumber',
        readOnly: true
        /*validator: () => (current) =>
          Yup.string()
            .nullable()
            .test(
              'isSet',
              'Veuillez introduire un numéro de téléphone valide',
              (v) => regExpPhoneNumber.fr.test(v) || isNil(v) || trim(v) === ''
            )
            .test(
              'oneOfRequired',
              'Veuillez renseigner au moins un numéro de téléphone.',
              (v) => v || current?.billingAddress?.customFields?.cellPhoneNumber
            )*/
      },
      {
        label: 'Portable',
        helper: 'Ex: 06XXXXXXXX ou 07XXXXXXXX',
        type: 'Input',
        attrKey: 'customFields.cellPhoneNumber',
        readOnly: true
        /*validator: () => (current) =>
          Yup.string()
            .nullable()
            .test(
              'isSet',
              'Veuillez introduire un numéro de téléphone valide',
              (v) => regExpPhoneNumber.fr.test(v) || isNil(v) || trim(v) === ''
            )
            .test(
              'oneOfRequired',
              'Veuillez renseigner au moins un numéro de téléphone.',
              (v) => v || current?.billingAddress?.phoneNumber
            )*/
      }
    ]
  }
]

export default formSettings
