import isEqual from 'lodash/isEqual'
import isNil from 'lodash/isNil'
import trim from 'lodash/trim'
import * as Yup from 'yup'

// TODO handle country in schema
const regExpZipCode = {
  FR: /^(?:[0-8]\d|9[0-8])\d{3}$/,
  BE: /^(?:(?:[1-9])(?:\d{3}))$/,
  CH: /^[1-9]\d{3}$/
}

const regExpCity = /^[a-zA-Z][a-zA-Z- ]*[a-zA-Z]$/

const regSpecCarc = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
const passwordValidates = (pass) => {
  let count = 0

  if (8 <= pass?.length && pass.length <= 32) {
    if (pass?.match('.*\\d.*')) count++
    if (pass?.match('.*[a-z].*')) count++
    if (pass?.match('.*[A-Z].*')) count++
    if (regSpecCarc.test(pass)) count++
  }
  return count >= 3
}
const regExpPhoneNumber = {
  FR: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
  BE: /^((\+|00)32\s?|0)(\d\s?\d{3}|\d{2}\s?\d{2})(\s?\d{2}){2}$/,
  CH: /^(?:(?:|0{1,2}|\+{0,2})41(?:|\(0\))|0)([1-9]\d)(\d{3})(\d{2})(\d{2})$/
}

const schema = {
  civility: {
    label: 'Civilité',
    type: 'Radio',
    values: ['Madame', 'Monsieur'],
    attrKey: 'title',
    required: true,
    hideLabel: true,
    schema: () => () => Yup.string().nullable().required('Ce champ est obligatoire')
  },
  lastName: {
    label: 'Nom',
    type: 'Input',
    attrKey: 'lastName',
    readOnly: true,
    required: true,
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(20, 'Doit être de 20 caractères maximum')
        .required('Ce champ est obligatoire')
  },
  firstName: {
    label: 'Prénom',
    type: 'Input',
    attrKey: 'firstName',
    readOnly: true,
    required: true,
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(20, 'Doit être de 20 caractères maximum')
        .required('Ce champ est obligatoire')
  },
  createLastName: {
    label: 'Nom',
    type: 'Input',
    attrKey: 'lastName',
    required: true,
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(20, 'Doit être de 20 caractères maximum')
        .required('Ce champ est obligatoire')
  },
  createFirstName: {
    label: 'Prénom',
    type: 'Input',
    attrKey: 'firstName',
    required: true,
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(20, 'Doit être de 20 caractères maximum')
        .required('Ce champ est obligatoire')
  },
  emailAddress: {
    label: 'E-mail',
    type: 'Input',
    attrKey: 'emailAddress',
    helpMessage: 'Ex: nom@yahoo.fr',
    required: true,
    schema: () => () =>
      Yup.string().nullable().email('Adresse e-mail invalide').required('Ce champ est obligatoire')
  },
  confirmEmailAddress: {
    label: 'Confirmez votre e-mail',
    type: 'Input',
    attrKey: 'confirmEmailAddress',
    required: true,
    schema: (origin) => (current) =>
      Yup.string()
        .nullable()
        .test('confirmed', 'Les adresses e-mail ne correspondent pas', (value) => {
          return (
            (origin?.emailAddress !== current?.emailAddress && current?.emailAddress === value) ||
            origin?.emailAddress === current?.emailAddress
          )
        }),
    visible: (origin) => (current) => origin?.emailAddress !== current?.emailAddress
  },
  birthdate: {
    label: 'Date de naissance',
    type: 'Date',
    attrKey: 'customFields.birthdate',
    required: true,
    schema: () => () => Yup.date().nullable().required('Ce champ est obligatoire')
  },
  city: {
    label: 'Ville',
    type: 'Input',
    helpMessage: 'Ex: PARIS',
    attrKey: 'city',
    required: true,
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(30, 'Doit être de 30 caractères maximum')
        .required('Ce champ est obligatoire')
        .test(
          'isSet',
          'Veuillez indiquer une ville valide.',
          (v) => regExpCity.test(v) || isNil(v) || trim(v) === ''
        )
  },
  country: {
    label: 'Pays',
    type: 'Input',
    attrKey: 'country.name',
    readOnly: true,
    required: true,
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(20, 'Doit être de 20 caractères maximum')
        .required('Ce champ est obligatoire')
  },
  postalCode: {
    label: 'Code postal',
    helpMessage: 'Veuillez indiquer un code postal valide.',
    type: 'Input',
    attrKey: 'postalCode',
    required: true,
    schema: () => () =>
      Yup.string()
        .nullable()
        .required('Ce champ est obligatoire')
        .matches(regExpZipCode[process.env.GATSBY_API_COUNTRY_CODE], {
          message: 'Veuillez indiquer un code postal valide.',
          excludeEmptyString: true
        })
  },
  addressLine1: {
    label: 'Adresse',
    type: 'Input',
    placeholder: 'N° et voie (rue, allée, avenue, boulevard...)',
    attrKey: 'streetLine1',
    helpMessage: '1 rue Victor Hugo',
    required: true,
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(40, 'Doit être de 40 caractères maximum')
        .required('Ce champ est obligatoire')
  },
  addressLine2: {
    type: 'Input',
    placeholder: 'Appartement, étage, porte, couloir...',
    helpMessage: 'Appt 25, Etage 3, Esc B',
    attrKey: 'streetLine2',
    schema: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
  },
  addressLine3: {
    type: 'Input',
    placeholder: 'Bâtiment, résidence, entrée...',
    helpMessage: 'Bât B, Rés Foch, Entrée B',
    attrKey: 'customFields.addressLine3',
    schema: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
  },
  addressLine4: {
    type: 'Input',
    placeholder: 'Lieu-dit',
    helpMessage: 'Bois Joly',
    attrKey: 'customFields.addressLine4',
    schema: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
  },
  phoneNumber: {
    label: 'Fixe',
    type: 'Input',
    helpMessage: 'Ex: 01XXXXXXXX',
    attrKey: 'phoneNumber',
    schema: () => (current) =>
      Yup.string()
        .nullable()
        .test(
          'isSet',
          'Veuillez introduire un numéro de téléphone valide',
          (v) => regExpPhoneNumber.fr.test(v) || isNil(v) || trim(v) === ''
        )
        .test(
          'oneOfRequired',
          'Veuillez renseigner au moins un numéro de téléphone.',
          (v) => v || current?.customFields?.cellPhoneNumber
        )
  },
  cellPhoneNumber: {
    label: 'Portable',
    helpMessage: 'Ex: 06XXXXXXXX ou 07XXXXXXXX',
    type: 'Input',
    attrKey: 'customFields.cellPhoneNumber',
    schema: () => (current) =>
      Yup.string()
        .nullable()
        .test(
          'isSet',
          'Veuillez introduire un numéro de téléphone valide',
          (v) => regExpPhoneNumber.fr.test(v) || isNil(v) || trim(v) === ''
        )
        .test(
          'oneOfRequired',
          'Veuillez renseigner au moins un numéro de téléphone.',
          (v) => v || current?.phoneNumber
        )
  },
  currentPassword: {
    label: 'Mot de passe actuel',
    type: 'Password',
    attrKey: 'currentPassword',
    required: true,
    schema: (origin) => (current) =>
      Yup.string()
        .nullable()
        .test(
          'hasChanged',
          'Veuillez renseigner votre mot de passe pour faire un changement',
          (v) => {
            const {
              // eslint-disable-next-line no-unused-vars
              currentPassword,
              // eslint-disable-next-line no-unused-vars
              newPassword,
              // eslint-disable-next-line no-unused-vars
              confirmNewPassword,
              // eslint-disable-next-line no-unused-vars
              confirmEmailAddress,
              ...cleanCurrent
            } = current
            return (isEqual(origin, cleanCurrent) && !current?.newPassword) || (v && trim(v) !== '')
          }
        )
  },
  newPassword: {
    label: 'Changer mon mot de passe',
    type: 'Password',
    attrKey: 'newPassword',
    schema: () => () =>
      Yup.string()
        .nullable()
        .test(
          'validPassword',
          `<div style="font-size:11px" class="password-error-message"><strong>Votre mot de passe doit contenir au moins 4 des 5 types suivants :</strong>
      <ul style="font-weight:400">
        <li>Minimum 8 caractères (obligatoire)</li>
        <li>Une minuscule</li>
        <li>Une majuscule</li>
        <li>Un chiffre</li>
        <li>Un caractère spécial</li></ul></div>`,
          (v) => {
            return !v || trim(v) === '' || passwordValidates(v)
          }
        ),
    passwordErrorComponent: true
  },
  confirmNewPassword: {
    label: 'Confirmer mon nouveau mot de passe',
    type: 'Password',
    attrKey: 'confirmNewPassword',
    required: true,
    visible: () => (current) => current?.newPassword,
    schema: () => (current) =>
      Yup.string()
        .nullable()
        .test('confirmed', 'Les mots de passe ne sont pas identiques', (v) => {
          return current?.newPassword === v || !current?.newPassword
        })
  },
  createNewPassword: {
    label: 'Mot de passe',
    type: 'Password',
    attrKey: 'newPassword',
    schema: () => () =>
      Yup.string()
        .nullable()
        .required('Ce champ est obligatoire')
        .test(
          'validPassword',
          `<div style="font-size:11px" class="password-error-message"><strong>Votre mot de passe doit contenir au moins 4 des 5 types suivants :</strong>
      <ul style="font-weight:400">
        <li>Minimum 8 caractères (obligatoire)</li>
        <li>Une minuscule</li>
        <li>Une majuscule</li>
        <li>Un chiffre</li>
        <li>Un caractère spécial</li></ul></div>`,
          (v) => {
            return !v || trim(v) === '' || passwordValidates(v)
          }
        ),
    passwordErrorComponent: true
  },
  createConfirmNewPassword: {
    label: 'Confirmez votre mot de passe',
    type: 'Password',
    attrKey: 'confirmNewPassword',
    required: true,
    visible: true,
    schema: () => (current) =>
      Yup.string()
        .required('Ce champ est obligatoire')
        .nullable()
        .test('confirmed', 'Les mots de passe ne sont pas identiques', (v) => {
          return current?.newPassword === v || !current?.newPassword
        })
  },
  addressFullName: {
    label: "Nom de l'adresse",
    type: 'Input',
    attrKey: 'fullName',
    helpMessage: 'Ex: Bureau, domicile...',
    required: true,
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(20, 'Doit être de 20 caractères maximum')
        .required('Ce champ est obligatoire')
  },
  addressCivility: {
    label: 'Civilité',
    type: 'Radio',
    values: ['Madame', 'Monsieur'],
    attrKey: 'customFields.civility',
    required: true,
    readOnly: (origin) => () => origin?.id,
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(20, 'Doit être de 20 caractères maximum')
        .required('Ce champ est obligatoire')
  },
  addressLastName: {
    label: 'Nom',
    type: 'Input',
    helpMessage: 'Ex: Dupont',
    attrKey: 'customFields.lastName',
    readOnly: (origin) => () => origin?.id,
    required: true,
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(20, 'Doit être de 20 caractères maximum')
        .required('Ce champ est obligatoire')
  },
  addressFirstName: {
    label: 'Prénom',
    type: 'Input',
    helpMessage: 'Ex: Jean',
    attrKey: 'customFields.firstName',
    readOnly: (origin) => () => origin?.id,
    required: true,
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(20, 'Doit être de 20 caractères maximum')
        .required('Ce champ est obligatoire')
  },
  addressCity: {
    label: 'Ville',
    type: 'Input',
    attrKey: 'city',
    helpMessage: 'Ex: PARIS',
    required: true,
    readOnly: (origin) => () => origin?.id,
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(30, 'Doit être de 30 caractères maximum')
        .required('Ce champ est obligatoire')
        .test(
          'isSet',
          'Veuillez indiquer une ville valide.',
          (v) => regExpCity.test(v) || isNil(v) || trim(v) === ''
        )
  },
  addressCountry: {
    label: 'Pays',
    type: 'Input',
    attrKey: 'country.name',
    readOnly: true,
    required: true,
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(20, 'Doit être de 20 caractères maximum')
        .required('Ce champ est obligatoire')
  },
  addressPostalCode: {
    label: 'Code postal',
    type: 'Input',
    attrKey: 'postalCode',
    helpMessage: 'Veuillez indiquer un code postal valide.',
    required: true,
    readOnly: (origin) => () => origin?.id,
    schema: () => () =>
      Yup.string()
        .nullable()
        .required('Ce champ est obligatoire')
        .matches(regExpZipCode[process.env.GATSBY_API_COUNTRY_CODE], {
          message: 'Veuillez indiquer un code postal valide.',
          excludeEmptyString: true
        })
  },
  addressAddressLine1: {
    label: 'Adresse',
    type: 'Input',
    placeholder: 'N° et voie (rue, allée, avenue, boulevard...)',
    attrKey: 'streetLine1',
    helpMessage: '1 rue Victor Hugo',
    required: true,
    readOnly: (origin) => () => origin?.id,
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(40, 'Doit être de 40 caractères maximum')
        .required('Ce champ est obligatoire')
  },
  addressAddressLine2: {
    type: 'Input',
    helpMessage: 'Appt 25, Etage 3, Esc B',
    placeholder: 'Appartement, étage, porte, couloir...',
    attrKey: 'streetLine2',
    readOnly: (origin) => () => origin?.id,
    schema: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
  },
  addressAddressLine3: {
    type: 'Input',
    helpMessage: 'Bât B, Rés Foch, Entrée B',
    placeholder: 'Bâtiment, résidence, entrée...',
    attrKey: 'customFields.addressLine3',
    readOnly: (origin) => () => origin?.id,
    schema: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
  },
  addressAddressLine4: {
    type: 'Input',
    placeholder: 'Lieu-dit',
    helpMessage: 'Bois Joly',
    attrKey: 'customFields.addressLine4',
    readOnly: (origin) => () => origin?.id,
    schema: () => () => Yup.string().nullable().max(40, 'Doit être de 40 caractères maximum')
  },
  addressPhoneNumber: {
    label: 'Fixe',
    type: 'Input',
    attrKey: 'phoneNumber',
    helpMessage: 'Ex: 01XXXXXXXX ou 06XXXXXXXX ou 07XXXXXXXX',
    readOnly: (origin) => () => origin?.id,
    schema: () => (current) =>
      Yup.string()
        .nullable()
        .test(
          'isSet',
          'Veuillez introduire un numéro de téléphone valide',
          (v) => regExpPhoneNumber.fr.test(v) || isNil(v) || trim(v) === ''
        )
        .test(
          'oneOfRequired',
          'Veuillez renseigner au moins un numéro de téléphone.',
          (v) => v || current?.customFields?.cellPhoneNumber
        )
  },
  addressCellPhoneNumber: {
    label: 'Portable',
    type: 'Input',
    attrKey: 'customFields.cellPhoneNumber',
    readOnly: (origin) => () => origin?.id,
    schema: () => (current) =>
      Yup.string()
        .nullable()
        .test(
          'isSet',
          'Veuillez introduire un numéro de téléphone valide',
          (v) => regExpPhoneNumber.fr.test(v) || isNil(v) || trim(v) === ''
        )
        .test(
          'oneOfRequired',
          'Veuillez renseigner au moins un numéro de téléphone.',
          (v) => v || current?.phoneNumber
        )
  },
  product: {
    label: 'Vous souhaitez retourner le produit suivant :',
    type: 'Checkbox',
    attrKey: 'product'
  },
  reason: {
    label: 'Quel est le motif de votre retour ?',
    placeholder: 'Selectionnez la raison de votre retour',
    values: {
      R01: 'Je souhaite bénéficier de la garantie satisfait ou remboursé',
      R02: 'Le produit que j’ai reçu ne correspond pas au produit que j’ai commandé',
      R07: 'Le produit que j’ai reçu est incomplet',
      R08: 'Le produit est en panne ou défectueux',
      other: 'Autre raison de mon retour'
    },
    type: 'Select',
    required: true,
    attrKey: 'reason',
    schema: () => () => Yup.string().nullable().required('Ce champ est obligatoire')
  },
  productReturnWish: {
    label: 'Quel est votre souhait suite à ce retour ?',
    placeholder: 'Vous souhaitez :',
    values: {
      R01: {
        ECA: 'Je souhaite procéder à un échange et recevoir le même produit d’une taille ou couleur différente',
        ANN: 'Je souhaite un remboursement'
      },
      R02: {
        ANN: 'Je souhaite un remboursement',
        other: 'J’ai une autre demande'
      },
      R07: {
        ECA: 'Je souhaite procéder à un échange et recevoir le même produit d’une taille ou couleur différente',
        ECM: 'Je souhaite procéder à un échange et recevoir un article identique'
      },
      R08: {
        ECM: 'Je souhaite procéder à un échange et recevoir un article identique'
      },
      other: {
        ECA: 'Je souhaite procéder à un échange et recevoir le même produit d’une taille ou couleur différente',
        ECM: 'Je souhaite procéder à un échange et recevoir un article identique',
        ANN: 'Je souhaite un remboursement',
        other: 'J’ai une autre demande'
      }
    },
    type: 'Select',
    required: true,
    attrKey: 'productReturnWish',
    schema: () => () => Yup.string().nullable().required('Ce champ est obligatoire')
  },
  comment: {
    type: 'Textarea',
    placeholder: 'Veuillez décrire votre problème',
    attrKey: 'comment',
    schema: () => () =>
      Yup.string()
        .nullable()
        .max(5000, 'Doit être de 500 caractères maximum')
        .required('Ce champ est obligatoire')
  }
}

export default schema
